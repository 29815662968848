module.exports = {
    init: function () {
        //Version mobile -------------------------------------------------------------------------------------------------

        let toggle_tools = document.getElementById("tools_button");
        let mobile_tools = document.getElementById("tools");
        let close_tools = document.getElementById("close_tools");

        if(!toggle_tools)
            return;

        let toggle = function(target){
            if (target.classList.contains('open')){
                target.classList.remove('open');
            }  else{
                target.classList.add('open')
            }
        };

        toggle_tools.addEventListener('click', function (e) {
            toggle_tools.style.animation = "bump .3s ease-in-out";
            toggle(mobile_tools);
        });

        close_tools.addEventListener('click', function () {
            toggle(mobile_tools);
        })
    }
};