module.exports = {
    init: function () {

        let test = document.getElementById('uploader_item');
        if(test) {
            files.remove.init();
            files.send.init();
            files.share.init();
        }
    },
    remove: {
        init: function() {
            let deleteBtns = document.querySelectorAll('.file_suppresser');
            for(let i = 0; i < deleteBtns.length; i++) {
                deleteBtns[i].addEventListener('click', files.remove.click);
            }
        },
        click: function(e) {
            //e.preventDefault();
            let goAhead = confirm('Envoyer le fichier dans la corbeille ?');
            if(!goAhead)
                e.preventDefault();
            return goAhead;
        },
    },
    send: {
        init: function() {
            //UX du formulaire d'upload de fichier

            let input = document.getElementById('uploader_item');
            let button = document.getElementById('file_submit');
            let label = document.querySelectorAll('#uploader label')[0],
                labelVal = label.innerHTML;

            input.addEventListener('change', function (e) {
                let fileName = '';
                if (this.files && this.files.length > 1)
                    fileName = (this.getAttribute('data-multiple-caption') || '').replace('{count}', this.files.length);
                else
                    fileName = e.target.value.split('\\').pop();

                if (fileName) {
                    label.innerHTML = fileName;
                } else {
                    label.innerHTML = labelVal;
                }
                button.style.opacity = '1';
            });


            const submitFile = document.getElementById('uploader');
            const submitFileButton = document.getElementById('file_submit');
            const maxSizeInput = document.getElementById('maxValue');

            submitFile.onsubmit = function (e) {
                if (!window.FileReader) {
                    alert("Le File API n'est pas supporté par ce navigateur. Des informations peuvent ne pas apparaître.")
                }
                let files = input.files;
                let flag = 0;
                if (files) {
                    for (let i = 0; i < files.length; i++) {
                        if (files[i].size > maxSizeInput.value) {
                            flag = 1;
                            e.preventDefault();

                            //Vidage des éventuelles notifications
                            messages.innerHTML = "<div class=\"message error\"><p>Le fichier " + files[i].name + " est trop gros : " + Math.round(files[i].size / 1000000 * 100) / 100 + " Mo (contre " + Math.round(maxSizeInput.value / 1000000 * 100) / 100 + " Max.)</p></div>";
                            notifications.show();
                        }
                    }
                    if (flag === 0) {
                        submitFileButton.innerText = "Envoi...";
                        submitFile.classList.add('sending');
                        setTimeout(function () {
                            messages.innerHTML = "<div class=\"message warning\"><p>La connexion semble lente. Cette opération peut prendre du temps...</p></div>";
                            notifications.show();
                        }, 2000)
                    }
                }
            };
        }
    },
    share: {
        init: function () {
            let shareBtns = document.querySelectorAll('.link-file-copy');
            for(let i = 0; i < shareBtns.length; i++) {
                shareBtns[i].addEventListener('click', files.share.click);
            }
        },
        click: function(e) {
            let lien = e.currentTarget.getAttribute('data-link');
            prompt("Copiez-collez le lien ci-dessous pour partager le fichier.", lien);
        }
    }
};