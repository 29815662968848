/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you require will output into a single css file (app.css in this case)
require('../css/app.scss');

// Need jQuery? Install it with "yarn add jquery", then uncomment to require it.
// const $ = require('jquery');

global.files = require("./files");
global.dirs = require("./dirs");
global.tooltips = require("./tooltips");
global.notifications = require("./notifications");
global.settings = require("./settings");
global.mobile = require("./mobile");


global.Swiper = require('swiper/js/swiper');

files.init();
dirs.init();
tooltips.init();
notifications.init();
settings.init();
mobile.init();


document.onkeydown = function (evt) {
    evt = evt || window.event;
    let isEscape = false;
    if ("key" in evt) {
        isEscape = (evt.key === "Escape" || evt.key === "Esc");
    } else {
        isEscape = (evt.keyCode === 27);
    }
    if (isEscape) {
        tooltips.closeAll();
        dirs.rename.unselect();
    }
};


global.colorHash = function(inputString) {
    let sum = 0;

    for(let i in inputString){
        sum += inputString.charCodeAt(i);
    }

    r = ~~(('0.'+Math.sin(sum+1).toString().substr(6))*256);
    g = ~~(('0.'+Math.sin(sum+2).toString().substr(6))*256);
    b = ~~(('0.'+Math.sin(sum+3).toString().substr(6))*256);

    let rgb = "rgb("+r+", "+g+", "+b+")";

    let hex = "#";

    hex += ("00" + r.toString(16)).substr(-2,2).toUpperCase();
    hex += ("00" + g.toString(16)).substr(-2,2).toUpperCase();
    hex += ("00" + b.toString(16)).substr(-2,2).toUpperCase();

    return {
        r: r
        ,g: g
        ,b: b
        ,rgb: rgb
        ,hex: hex
    };
};