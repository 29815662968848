module.exports = {
    opening: false,
    init: function() {
        let tooltipA = document.querySelectorAll(".tooltipmenucontainer > a");
        for(let i = 0; i < tooltipA.length; i++) {
            tooltipA[i].addEventListener('click', tooltips.openMenu);
        }
        document.querySelectorAll('body')[0].addEventListener('click', tooltips.closeAll);
    },

    openMenu: function(e) {
        e.preventDefault();
        let tooltip = e.currentTarget.parentNode.querySelectorAll(".tooltipmenu")[0];
        let isOpen = tooltip.classList.contains('open');
        tooltips.closeAll();
        if(!isOpen) {
            tooltips.opening = true;
            tooltip.classList.add('open');
        }
        setTimeout(function() {tooltips.opening = false;}, 200);
    },

    closeAll: function(e) {
        if(tooltips.opening)
            return;
        let tlist = document.querySelectorAll(".tooltipmenu");
        for(let i = 0; i < tlist.length; i++)
            tlist[i].classList.remove('open');
    }
};